<template>
  <v-sheet
      class="fill-height wsRoundedLight"
      color="transparent">

    <avalon-video-upload-dialog
        v-if="displayLibrary"
        v-model="displayLibrary"
        @select="updateVideo"
        :element-id="element.id"
    />

    <div style="pointer-events: none" v-if="element.config.video" class="fill-height">
      <ws-video-viewer
          :key="element.config.video.uuid"
          :url="element.config.video.is_embed ? element.config.video.url : null"
          :file="element.config.video.is_uploaded ?  element.config.video : null"
          full-screen
      />
    </div>

    <v-sheet
        v-else
        :color="wsLIGHTCARD"
        :style="`border : 2px dashed ${wsACCENT}`"
        style="position: relative"
        class="fill-height d-flex align-center wsRoundedLight "
    >

      <div style="width: 100%">

        <div class="d-flex justify-center">
          <v-btn @click="openVideoDialog"  :color="wsATTENTION" icon>
            <v-icon large   :color="wsATTENTION">mdi-plus-circle</v-icon>
          </v-btn>
        </div>

        <h5 v-if="(element.x2- element.x1) > 4 && (element.y2 - element.y1) > 4"
            :style="`color : ${wsACCENT}`"
            style="max-width: 180px"
            class="mt-2 mx-auto text-center"
        >
          {{ $t('uploader.choose_video') }}
        </h5>
      </div>


    </v-sheet>

  </v-sheet>
</template>

<script>
import avalonVideoUploadDialog from "@/components/AvalonEditor/Dialogs/avalonVideoUploadDialog";

import {mapActions} from "vuex";
export default {
  name: "elementImageEditor",
  components : {
    avalonVideoUploadDialog
  },
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    template : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      element : {},
      displayLibrary : false,
    }
  },
  computed : {
    addImageActionsSelect() {
      return [
        { text : this.$t('Upload') , value : 'upload' , icon : 'mdi-upload' },
        { text : this.$t('BrowseCollection') , value : 'browse' , icon : 'mdi-grid' }
      ]
    },
    imageStyle() {
      let style = ''
      if ( this.element.config.contain ) {
        return style
      }

      style +=  `background: url('${this.element.text}');
                 background-size : cover;
                 position:relative;`
      if ( !this.MOBILE_VIEW ) {
        style += `background-position : ${this.element.config.image_position_x}% ${this.element.config.image_position_y}%;`
      } else {
        style += `background-position : ${this.element.config.image_position_sm_x}% ${this.element.config.image_position_sm_y}%;`
      }

      if ( this.element.config.rounded ) {
        style += `border-radius : ${this.element.config.rounded}px;`
      }

      style += this.GET_CLIPPING_MASK(this.element.config.mask || null , this.element.config.mask_resize)

      return style
    }
  },
  watch : {
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),

    updateVideo(video) {
      this.element.config.video = video
      this.element = this.COPY(this.element)
      this.$emit('input',this.element)
      this.EDIT_ELEMENT(this.element)
    },
    openVideoDialog( ) {
      this.displayLibrary = true
    },
    setImage(url) {
      this.element.text = url
      this.$emit('input',this.element)
    }
  },
  mounted(){
    if ( this.value ) {
      this.element = this.COPY(this.value)
      this.$emit('input',this.element)
    }
  }
}
</script>

<style scoped>

</style>